import React,{useState} from 'react'
import { Card, Button,Modal } from 'antd';
import actions from "redux/virtual/altRealm/actions";
import { useDispatch, useSelector } from "react-redux";
import Avaturn from './avaturn';
import AvatarCustomizer from '.';
import { CloseCircleTwoTone } from '@ant-design/icons'
import AvatarOsImg from "assets/images/avatarOS.jpg";
import AvaturnImg from "assets/images/icons/Avaturn.jpg";
import Readyplayer from "assets/images/icons/RPM.jpg";
import './avaturn.less'
function SelectPlatform() {
    const requiredState = useSelector(({ altRealm }) => ({ altRealm }));
    const dispatch = useDispatch();
  
    const { currentPlatform ,platformSelection ,isAvatarCustomizerOpen ,isAvatarInitial ,isPlatformCustomizer } = requiredState?.altRealm
  const [platform, setplatform] = useState(
    [
     
      {
        title: 'avaturn',
        content: <Avaturn/>,
      },
      {
        title: 'readyplayerme',
        content: <AvatarCustomizer/>,
      },
      // {
      //   title: 'readyplayerme',
      //   content: <AvatarCustomizer />,
      // },
    ]
  )
  
  const closeModal = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isAvatarCustomizerOpen: false,
      },
    })
  }
    const handleSelectPlatform = (value ,index)=>{
      console.log(value,"value playform");
      if(value=="avatarOs"){
        dispatch({
          type: actions.SET_STATE,
          payload: {
            platform:value,
            isAvatarCustomizerOpen:false
            // platformSelection:false,

      }
    });
    dispatch({
      type: actions.SEND_AVATAR,
      payload:{
        instance: "later",
        
      }
    })

      }else{

        dispatch({
            type: actions.SET_STATE,
            payload: {
            platform:value,
            currentPlatform:index,
            // isAvatarCustomizerOpen:true,
            platformSelection:false,
            },
          });
      }
    }



  return (
    <Modal
    className='avatar_Modal'
      visible={isAvatarCustomizerOpen}
      wrapClassName="avatarModal"
      maskClosable={false}
      mask
      width="1280px"
      height="800px"
      centered
      onOk={closeModal}
      confirmLoading
      onCancel={closeModal}
      footer={null}
      closable={isAvatarInitial ? false : true}
      closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
      maskStyle={{ backgroundColor: 'rgba(27, 27, 27, 0.3)' }}
    >
      
{platformSelection ? ( <div className='cardDiv' style={{ display: 'flex', justifyContent: 'space-around' }}>
<h1> Choose Avatar Platform </h1>
<Card
 className="game-card"
        hoverable
        
        cover={
          <div className="game-card-image">
        <img className='platformImage' alt="example" src={AvatarOsImg}  />
        
        <div className="game-card-overlay">
            <div className="game-card-overlay-content">
              <h3>Avatar Os</h3>
              <Button  ghost onClick={()=>handleSelectPlatform("avatarOs")}>Enter</Button>
            </div>
          </div>
          </div>}

      >
     

      </Card>
      <Card
 className="game-card"
        hoverable
        
        cover={
          <div className="game-card-image">
        <img className='platformImage' alt="example" src={AvaturnImg}  />
        
        <div className="game-card-overlay">
            <div className="game-card-overlay-content">
              <h3>Avaturn</h3>
              <Button   ghost onClick={()=>handleSelectPlatform("avaturn",0)}>Enter</Button>
            </div>
          </div>
          </div>}

      >
     

      </Card>

      <Card
 className="game-card"
        hoverable
        
        cover={
          <div className="game-card-image">
        <img className='platformImage' alt="example" src={Readyplayer}  />
        
        <div className="game-card-overlay">
            <div className="game-card-overlay-content">
              <h3>Ready Player Me</h3>
              <Button  ghost onClick={()=>handleSelectPlatform("readyPlayerMe",1)}>Enter</Button>
            </div>
          </div>
          </div>}

      >
      </Card>

   
    </div>): <>
    {platform[currentPlatform].content}
    </> }
    


       
    </Modal>
  )
}

export default SelectPlatform