import axios from "axios";

const AUTH_URL = "https://api.avatarsdk.com/"

export async function authorization() {
  let auth_form = new FormData();
  auth_form.append("grant_type", "client_credentials");

  let auth =
    "Basic " +
    btoa(
      process.env.REACT_APP_AVATARSDK_CLIENT_ID +
        ":" +
        process.env.REACT_APP_AVATARSDK_CLIENT_SECRETKEY    
    );

  const {data} =  axios.post(AUTH_URL +'o/token/', auth_form, {
    headers: {
      Authorization: auth,
    },
  });
  return data;
}
export async function createPlayerId(value) {
    let auth_form = new FormData();
    auth_form.append("comment", "create player");
  
   
  
    const {data} =  axios.post(AUTH_URL +'players/', auth_form, {
        headers: {
            Authorization: `Bearer ${value.token}`,
          },
    });
    return data;
  }
