import { all, takeEvery, call, put,select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { message } from 'antd'
import actions from './actions'
import { useUnityContext } from "react-unity-webgl";
import { getSavedData,getAvatarRender } from 'services/organizer/eventSetup'
import {updateTouchPointScore} from 'services/virtual/realm'
import userActions from 'redux/virtual/user/actions'
import OrganizerActions from 'redux/organizer/agenda/actions'

import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import { authorization,createPlayerId } from 'services/virtual/avatarSdk';
// import menuActions from '../../common/menu/actions'


export function* AVATAR_AUTH(data) {
  try {
    const result = yield call(authorization)
    console.log(result)
    yield put({
      type: actions.SET_STATE,
      payload: {
        avatarAccessToken: data?.access_token,
      },
    })
  } catch (err) {
    console.log(err)
  }
}

export function* CREATE_PLAYER(data) {
  try {
    const state = yield select()
    const {
      avatarSdk: { avatarAccessToken },
    } = state
    
    yield call(createPlayerId, {
      token: avatarAccessToken,
    })
  } catch (err) {
    console.log(err)
  }
}


export default function* rootSaga() {
  yield all([
  
    takeEvery(actions.AVATAR_AUTH, AVATAR_AUTH),
    takeEvery(actions.CREATE_PLAYER, CREATE_PLAYER),

  ])
  
}