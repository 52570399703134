import React, { useEffect,useRef, useState } from 'react';
import {
  Modal,
  // Row,
  // Col,
  // Button,
} from 'antd'
import Iframe from 'react-iframe'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import "./avatar.less"
import { getAvatarDetails } from 'services/virtual/realm';
import AvtarModal from './avtarModal';

export default function AvatarCustomizer() {
  const requiredState = useSelector(({ altRealm, virtualUser }) => ({ altRealm, virtualUser }))
  const dispatch = useDispatch();
  const { altRealm: { isAvatarCustomizerOpen,isAvatarInitial }, virtualUser: { avatarDataLoaded } } = requiredState
  const subdomain = 'altyug' // See section about becoming a partner
  const iFrameRef = useRef(null)
  const [avatarUrl, setAvatarUrl] = useState('')
  const [showIFrame, setShowIFrame] = useState(true)

  useEffect(() => {
    let iFrame = iFrameRef.current
    if (iFrame) {
      iFrame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`
    }
  },[isAvatarCustomizerOpen])

  useEffect(() => {
    window.addEventListener('message', subscribe)
    document.addEventListener('message', subscribe)
    return () => {
      window.removeEventListener('message', subscribe)
      document.removeEventListener('message', subscribe)
    }
  });


  const closeModal = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isAvatarCustomizerOpen: false,
      },
    })
  }

  function subscribe(event) {
    const json = parse(event)
    if (json?.source !== 'readyplayerme') {
      return;
    }
    // Subscribe to all events sent from Ready Player Me 
    // once frame is ready
    if (json.eventName === 'v1.frame.ready') {
      let iFrame = iFrameRef.current
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**'
          }),
          '*'
        );
      }
    }
    // Get avatar GLB URL
    if (json.eventName === 'v1.avatar.exported') {
     
      getAvatarDetails(json.data.url).then((res)=>{
        console.log(res)
        const avatarElements =  json.data.url.split('/')
        const thelastPart = avatarElements[avatarElements.length-1]
        const avatarID = thelastPart.split('.')[0]
        console.log(avatarID,"avatarID")
        dispatch({
          type: actions.SET_STATE,
          payload: {
            isAvatarCustomizerOpen: false,
            gender:res?.data?.outfitGender,
            avatarID
          },
        })

        dispatch({
          type: actions.SEND_AVATAR,
          payload:{
            avatarURL: json.data.url,
            instance: "later",
            
          }
        })

      })
    }
    // Get user id
    if (json.eventName === 'v1.user.set') {
      console.log(`User with id ${json.data.id} set:
${JSON.stringify(json)}`);
    }
  }
  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }


  return (
    <iframe
        allow="camera *; microphone *"
        className="iFrame theavatariFrame"
        id="frame"
        width="100%"
        height="800px"
        frameBorder={0}
        style={{ border: 0, display: "initial", position: "relative"}}
        ref={iFrameRef}
      />
  );
}
