import React from 'react';
import {
  Modal,
  Row,
  Col
} from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import Controls from './controls';
import "./instructions.less"

export default function Instructions() {
  const requiredState = useSelector(({altRealm })=>({altRealm}))
  const dispatch = useDispatch();
  const {altRealm:{isInstructionsOpen}} = requiredState


  const closeModal  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isInstructionsOpen: false,
      },
    })
  }

  return (
    <Modal
      visible={isInstructionsOpen}
      wrapClassName="instructionsModal"
      maskClosable
      mask
      width="40vw"
      height="15vh"
      centered
      onOk={closeModal}
      confirmLoading
      onCancel={closeModal}
      footer={null}
      closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
      maskStyle={{ backgroundColor: 'rgba(27, 27, 27, 0.3)' }}
      destroyOnClose
    >
      <Row>
        <Col span={24} className="hp-text-center">
          <h2 style={{color: "black"}}>Controls</h2>
        </Col>
      </Row>
      <Controls />
    </Modal>
  );
}
          