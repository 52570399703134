import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from 'antd'
import Iframe from 'react-iframe'
import AltyugCoin from "assets/images/icons/altyugcoin.png";
import actions from 'redux/virtual/altRealm/actions'
import './gamingScreen.less'
import SelectPlatform from 'view/common/components/AvatarCustomizer/selectPlatform';

/* eslint-disable react/jsx-indent */

export default function GamingScreen() {
  const requiredState = useSelector(({ altRealm, virtualUser }) => ({ altRealm, virtualUser }))
  const { altRealm: { gamingData, isGameStart }, virtualUser: { id,email,name } } = requiredState
  const dispatch = useDispatch();

  const handleStartGame = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isGameStart: true,
      },
    })
  }

  console.log('process.env.REACT_APP_ENDPOINT', process.env.REACT_APP_ENDPOINT)
  const endpoint = process.env.REACT_APP_ENDPOINT
  return (
    <div className="gaming_screen text-center">
      {isGameStart ? (
        // <Iframe
        //   url={`${gamingData.gameurl}?gameid=${gamingData.widgetID}&userid=${id}&endpoint=${endpoint}&pointingsystem=${gamingData.pointsystem}&user_nickname=${name}&user_email=${email}`}
        //   width="100%"
        //   height="calc(100vh - 200px)"
        //   loading="eager"
        //   className="theiFrame"
        //   display="initial"
        //   position="relative"
        //   frameborder="0"
        // />
        <SelectPlatform/>
      ) : (
        <Row>
          <Col xs={24} sm={24} md={10}>
            <span className="game_logo_container">
              <img src={gamingData.gameIcon} className="game_logo_img" alt="game logo" />
            </span>
          </Col>
          <Col md={1} />
          <Col xs={24} sm={24} md={12}>
            <span className="game_desc_container">
              <h2 style={{ fontWeight: 900 }}>{gamingData.gamename}</h2>
              <br />
              <p>{gamingData.game_instn}</p>

              <div className="points-row">
                <span className="points-container">
                  <p>{gamingData.pointsystem} point(s)</p>
                  <p>&nbsp;= 1 <img src={AltyugCoin} style={{width: "13%"}} alt="coin" /></p>

                </span>
              </div>
              <br />
              <Button type="primary" onClick={handleStartGame}>
                Start Game
              </Button>
            </span>
          </Col>
          <Col md={1} />
        </Row>
      )}
    </div>
  )
}

