import React from 'react';
import {
  Row,
  Col,
} from 'antd'
import MoveControl from "assets/images/icons/move.png"
import KeysControl from "assets/images/icons/keys.png"
import ShiftControl from "assets/images/icons/shift.png"
import SpacebarControl from "assets/images/icons/spacebar.png"
import "./instructions.less"

export default function Controls() {



  return (
    <>
      <Row>
        <Col span={9} />
        <Col span={6} className="hp-text-center">
          <img src={MoveControl} style={{ width: "100%"}} />
          <br />
          Grab and drag the mouse to look around
        </Col>
        <Col span={9} />
      </Row>
      <br />
      <br />
      <Row>
        <Col span={1} />
        <Col span={6} className="hp-text-center">
          <img src={KeysControl} style={{ width: "100%"}} />
          <br />
          Use keys WASD to Move
        </Col>
        <Col span={2} />
        <Col span={6} className="hp-text-center">
          <img src={ShiftControl} style={{ width: "100%"}} />
          <br />
          Hold Shift with WASD keys to run
        </Col>
        <Col span={2} />
        <Col span={6} className="hp-text-center">
          <img src={SpacebarControl} style={{ width: "100%"}} />
          <br />
          Use Spacebar to Jump
        </Col>
        <Col span={1} />
      </Row>
    </>
  );
}
          