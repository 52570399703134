import React, { useEffect,useRef, useState } from 'react';
import {
  Modal,
  // Row,
  // Col,
  // Button,
} from 'antd'
import Iframe from 'react-iframe'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import "./avaturn.less"
import { getAvatarDetails } from 'services/virtual/realm';
import AvtarModal from './avtarModal';

export default function Avaturn() {
  const requiredState = useSelector(({ altRealm, virtualUser }) => ({ altRealm, virtualUser }))
  const dispatch = useDispatch();
  const { altRealm: { isAvatarCustomizerOpen,isAvatarInitial }, virtualUser: { avatarDataLoaded } } = requiredState
  const subdomain = 'altyug' // See section about becoming a partner
  const iFrameRef = useRef(null)
  const [avatarUrl, setAvatarUrl] = useState('')
  const [showIFrame, setShowIFrame] = useState(true)

  useEffect(() => {
    let iFrame = iFrameRef.current
    if (iFrame) {
      iFrame.src = `https://${subdomain}.avaturn.dev/iframe`
    }
  },[isAvatarCustomizerOpen])

  useEffect(() => {
    window.addEventListener('message', subscribe)
    document.addEventListener('message', subscribe)
    return () => {
      window.removeEventListener('message', subscribe)
      document.removeEventListener('message', subscribe)
    }
  });


  const closeModal = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isAvatarCustomizerOpen: false,
      },
    })
  }

  function subscribe(event) {
    /* Here we process the events from the iframe */
    let json;
  try {
    json = JSON.parse(event.data);
  } catch (error) {
    console.log('Error parsing the event data.');
    return;
  }

  if (json.source !== 'avaturn') {
    return;
  }

    // Get avatar GLB URL
    if (json.eventName === 'v2.avatar.exported') {
      console.log(json,"jsonData")
       
            // const avatarElements =  json.data.url.split('/')
            // const thelastPart = avatarElements[avatarElements.length-1]
            // const avatarID = thelastPart.split('.')[0]
            // console.log(avatarID,"avatarID")
            dispatch({
              type: actions.SET_STATE,
              payload: {
                isAvatarCustomizerOpen: false,
                // gender:"masculine",

                // avatarID
              },
            })
    
            dispatch({
              type: actions.SEND_AVATAR,
              payload:{
                avatarURL: json.data.url,
                instance: "later",
                
              }
            })
    
    }
  }


  return (
   <iframe
        allow="camera *; microphone *"
        className="iFrame theavatariFrame"
        id="frame"
        width="100%"
        height="800px"
        frameBorder={0}
        style={{ border: 0, display: "initial", position: "relative"}}
        ref={iFrameRef}
      />
  );
}
