import { all, takeEvery, call, put,select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { message } from 'antd'
import actions from './actions'
import { useUnityContext } from "react-unity-webgl";
import { getSavedData,getAvatarRender } from 'services/organizer/eventSetup'
import {updateTouchPointScore} from 'services/virtual/realm'
import userActions from 'redux/virtual/user/actions'
import OrganizerActions from 'redux/organizer/agenda/actions'

import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
// import menuActions from '../../common/menu/actions'


export function* GET_INIT() {
  try {
    const setupData = yield call(getSavedData)
    // console.log(setupData)
    if (Object.keys(setupData.data.realms).length > 0) {
      const final = setupData.data.realms
      yield put({
        type: actions.SET_STATE,
        payload: {
          realms: final,
          currentRealm: Object.keys(final)[0],
        },
      })

     


      // const tempCategories = {...categories}
      // Object.entries(setupData.data.realms).map(theRealm => {
      //   Object.entries(categories).map(key => {
      //       tempCategories[key[0]].slots = final[theRealm[0]]
      //   })
      // })
      yield put({
        type: actions.SET_STATE,
        payload: {
          realms: final,
          currentRealm: Object.keys(final)[0],
        },
      })

      yield put(push(`/realm/${Object.keys(final)[0]}`))
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SET_DATA(data) {
  try {
    // console.log("inside SET_DATA")
    // console.log(data)
    const state = yield select()
    const {
      altRealm: {currentRealm,realms}
    } = state

    const tempRealms = realms
    tempRealms[currentRealm][data.payload.currentEditorScreen].data = data.payload.actualload
      yield put({
        type: actions.SET_STATE,
        payload: {
          realms: tempRealms
        },
      })
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export function* SEND_AVATAR(data) {
  try {
    const state = yield select()
    const {
      virtualUser: {avatarData, name, accessToken, id, roles},
      gameContext: {isLoaded},
      altRealm:{gender,platform}

    } = state
    console.log("platform in saga :::",platform )
    const selectedLayout = isMobile ? isMobileOnly ? "BottomLayout" : "VerticalLayout" : "HorizontalLayout"
    const nameArr = name.trim().split(/\s+/);
    const firstName = nameArr[0]
    const jsonTobeSent = {
      "avatarURL": data.payload.avatarURL,
      "name": firstName,
      "jwt": accessToken,
      "uuid": id,
      "isMobile": isMobile,
      "isAdmin": roles.includes('ROLE_ADMIN'),
      "outfitGender":gender,
      "platform":platform
    }
    if(isLoaded){
      if(data.payload.avatarURL !== avatarData || data.payload.instance === "initial" || data.payload.instance === "reconnect")
      {
       
      console.log(jsonTobeSent,"jsonTobeSent")
        yield put({
          type: actions.SEND_UNITY_MESSAGE,
          payload: {
            // gameObjectName: "AvatarManager",
            gameObjectName: "Launcher",
            methodName: "StartAvatar",
         
            parameter: JSON.stringify(jsonTobeSent)
          }
        })
      }else{
        console.log("update avatarr");
        console.log(jsonTobeSent,"jsonTobeSent");

        yield put({
          type: actions.SEND_UNITY_MESSAGE,
          payload: {
            gameObjectName: "ChangeAvatarAtRuntime",
            methodName: "ChangeAvatarAtRunTime",
            parameter: JSON.stringify(jsonTobeSent)
          }
        })
      }
    }
    yield put({
      type: userActions.SET_STATE,
      payload: {
        avatarType: "avatar",
        avatarData: data.payload.avatarURL,
      }
    })

    if(data.payload.instance !== "initial")
    {
      yield put({
        type: userActions.COMPLETE_EDIT_PROFILE
      })
    }


  } catch (err) {
    console.log(err)
  }
}


export function* SEND_REALM_DATA(data) {
  try {
    const state = yield select()
    const {
      altRealm: {realms, currentRealm},
      gameContext: {isLoaded}
    } = state

    const selectedRealm = realms[currentRealm]
    const theProductArray = []
    selectedRealm.products.data.forEach(element => {
      theProductArray.push({
        "uuid": element.uuid,
        "url": element.url,
        "scale": element.scale,
        "rotation": element.rotation
      })
    });

    if(isLoaded){
      const jsonTobeSent = {
        "products": theProductArray,
        "videos": selectedRealm.videos.data,
        "images":selectedRealm.images.data,
        "iframes": selectedRealm.iframes.data,
      }
      // console.log("JSOn load to be sent")
      // console.log(jsonTobeSent)
      // console.log(JSON.stringify(jsonTobeSent))
      yield put({
        type: actions.SEND_UNITY_MESSAGE,
        payload: {
          // gameObjectName: "AvatarManager",
          gameObjectName: "JsonConfiguration",
          methodName: "LoadJson",
          parameter: JSON.stringify(jsonTobeSent)
        }
      })
    }

  } catch (err) {
    console.log(err)
  }
}


export function* SEND_UNITY_MESSAGE(data) {
  console.log(data,"dataa");
  try {
    const state = yield select()
    const {
      gameContext: {sendMessage, isLoaded}
    } = state
    const {gameObjectName, methodName, parameter} = data.payload
    if(isLoaded){
      // console.log("inside sending unity data")
      // console.log("Is application loaded? "+isLoaded)
      // console.log(data.payload)
      sendMessage(gameObjectName,methodName,parameter)
    }
    else{
      console.log("Application is not loaded, we are not sending any data.")
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_AVATAR_RENDER(data) {
  try {
    const state = yield select()
    const {
      virtualUser: {avatarData}
    } = state
    yield put({
      type: actions.SET_STATE,
      payload: {
        photoboothStep: 1,
        imageProcessing: true
      },
    })
    const result = yield call(getAvatarRender, {avatar: avatarData})
    // console.log("rendered image")
    // console.log(result)
    yield put({
      type: actions.SET_STATE,
      payload: {
        photoboothUrl: result.renders,
        imageProcessing: false
      },
    })
  } catch (err) {
    console.log(err)
  }
}

export function* UPDATE_ELEMENT_SCORE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state

    const { elementId,touchPointId,touchPointName } = data.payload

    const result = yield call(updateTouchPointScore, {
      token: accessToken,
      touchPointId,
      elementId,
      touchPointName
    })

    // if(result.status === 200){
    //   notification.success({
    //     message: 'Points Added',
    //     duration: 5,
    //     description:
    //       'Points have been successfully added to the leaderboard.',
    //   })
    // }

  } catch (err) {
    console.log(err)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),
    takeEvery(actions.SEND_UNITY_MESSAGE, SEND_UNITY_MESSAGE),
    takeEvery(actions.SEND_AVATAR, SEND_AVATAR),
    takeEvery(actions.SEND_REALM_DATA, SEND_REALM_DATA),
    takeEvery(actions.SET_DATA, SET_DATA),
    takeEvery(actions.GET_AVATAR_RENDER, GET_AVATAR_RENDER),
    takeEvery(actions.UPDATE_ELEMENT_SCORE, UPDATE_ELEMENT_SCORE),
  ])
}