import React from 'react'
import { useSelector,useDispatch } from "react-redux";
import { Row, Col,Button, Space } from "antd";
import './avatar.less'
import './uploadImage.less'

import actions from 'redux/virtual/altRealm/actions';
import {AiOutlineMan,AiOutlineWoman} from 'react-icons/ai'
import{IoIosTransgender} from 'react-icons/io'
import{FcUpload} from 'react-icons/fc'

import avatarAction from "redux/virtual/avatarSdk/actions";

function AvtarModal() {
  const requiredState = useSelector(({ altRealm }) => ({ altRealm }));
    
    const steps = [
        {
          title: 'StepOne',
          content: <StepOne/>,
        },
     
        {
          title: 'StepTwo',
          content: <StepTwo />,
        },
        {
          title: 'StepThree',
          content: <CardProfile />,
        },

      ]
console.log(requiredState.altRealm,"requiredState.altRealm.")

  return (
   
<>
{steps[requiredState.altRealm.isAvatarCustomizerStep].content}
</>

      
      
  )
}

export function StepOne (){
  const dispatch = useDispatch();
  const requiredState = useSelector(({ altRealm }) => ({ altRealm }));

  const { altRealm: { isAvatarCustomizerStep }} = requiredState


    function handleSelectGender(value){
        dispatch({
            type: actions.SET_STATE,
            payload: {
                isAvatarCustomizerStep:isAvatarCustomizerStep+1,
                genderSelected:value
            },
          })
      

    }
    return(
        <div className='ModalDiv'>
        <span className='text'>Choose Gender</span>
        <Space
        direction="vertical"
        className='verticalSpace'
        style={{
            width: '100%',
          }}
      >
        <Button onClick={()=>handleSelectGender('Male')}  type="dashed" block>
         Male
         <span><AiOutlineMan/></span>
         <span></span>
        </Button>
        <Button onClick={()=>handleSelectGender('Female')} type="dashed" block>
          Female
         <span><AiOutlineWoman/></span>

        </Button>
        <Button onClick={()=>handleSelectGender('other')} type="dashed" block>
          Other
         <span><IoIosTransgender/></span>

        </Button>
       
      </Space>
        </div>
    
    )

}
export function StepTwo (){
    const dispatch = useDispatch();
    const requiredState = useSelector(({ altRealm }) => ({ altRealm }));
  
    const { altRealm: { isAvatarCustomizerStep }} = requiredState
  
  
      function handleSelectBodyType(value){
          dispatch({
              type: actions.SET_STATE,
              payload: {
                  isAvatarCustomizerStep:isAvatarCustomizerStep+1,
                  BodyTypeSelected:value
              },
            })
            // dispatch({
            //   type: avatarAction.CREATE_PLAYER,
           
            // })
            
        
  
      }
    return(
        <div className='ModalDiv'>
        <span className='text'>Choose Body Type</span>
        <Space
        direction="vertical"
        className='verticalSpace'
        style={{
            width: '100%',
          }}
      >
        <Button onClick={()=>handleSelectBodyType('Slim')}  type="dashed" block>
        Slim
        </Button>
        <Button onClick={()=>handleSelectBodyType('Muscular')} type="dashed" block>
        Obese
        </Button>
        <Button onClick={()=>handleSelectBodyType('Obese')} type="dashed" block>
        Fat
        </Button>
       
      </Space>
        </div>
    )

}
export function StepThree (){
  const dispatch = useDispatch();
  const requiredState = useSelector(({ altRealm }) => ({ altRealm }));

  const { altRealm: { isAvatarCustomizerStep }} = requiredState


    function handleSelectBodyType(value){
        dispatch({
            type: actions.SET_STATE,
            payload: {
                isAvatarCustomizerStep:isAvatarCustomizerStep+1,
                BodyTypeSelected:value
            },
          })
          // dispatch({
          //   type: avatarAction.CREATE_PLAYER,
         
          // })
          
      

    }
    
const   photoUpload = e =>{
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file);
  }
 const editName = e =>{
    const name = e.target.value;
    this.setState({
      name,
    });
  }
  
 const editStatus = e => {
    const status = e.target.value;
    this.setState({
      status,
    });
  }
  
 const  handleSubmit= e =>{
    e.preventDefault();
    let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
    this.setState({
      active: activeP,
    })
  }
  return(
      <div className='ModalDiv'>
      <span className='text'>Choose Body Type</span>
      <Space
      direction="vertical"
      className='verticalSpace'
      style={{
          width: '100%',
        }}
    >
      <Button onClick={()=>handleSelectBodyType('Slim')}  type="dashed" block>
      Slim
      </Button>
      <Button onClick={()=>handleSelectBodyType('Muscular')} type="dashed" block>
      Obese
      </Button>
      <Button onClick={()=>handleSelectBodyType('Obese')} type="dashed" block>
      Fat
      </Button>
     
    </Space>
      </div>
  )

}
export default AvtarModal


const ImgUpload =({
  onChange,
  src
})=>
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload" >
      <img for="photo-upload" src={src}/>
    </div>
    <input id="photo-upload" type="file" onChange={onChange}/> 
  </label>


const Name =({
  onChange,
  value
})=>
  <div className="field">
    <label htmlFor="name">
      name:
    </label>
    <input 
      id="name" 
      type="text" 
      onChange={onChange} 
      maxlength="25" 
      value={value} 
      placeholder="Alexa" 
      required/>
  </div>

  
const Status =({
  onChange,
  value
})=>
  <div className="field">
    <label htmlFor="status">
      status:
    </label>
    <input 
      id="status" 
      type="text" 
      onChange={onChange} 
      maxLength="35" 
      value={value} 
      placeholder="It's a nice day!" 
      required/>
  </div>


const Profile =({
  onSubmit,
  src,
  name,
  status,
})=>
  <div className="profileCard">
    <form className='profileForm' onSubmit={onSubmit}>
      <h1>Profile profileCard</h1>
      <label className="custom-file-upload fas">
        <div className="img-wrap" >
          <img for="photo-upload" src={src}/>
        </div>
      </label>
      <div className="name">{name}</div>
      <div className="status">{status}</div>
      <button type="submit" className="edit">Edit Profile </button>
    </form>
  </div>
     
      
const Edit =({
  onSubmit,
  children,
})=>
  <div className="profileCard">
    <form className='profileForm' onSubmit={onSubmit}>
      <h1 style={{color:"#000"}}>Upload Avatar</h1>
        {children}
      <button type="submit" className="save">Save </button>
    </form>
  </div>

class CardProfile extends React.Component {
  state = {
    file: '',
    imagePreviewUrl: 'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true',
    name:'',
    status:'',
    active: 'edit'
  }

  photoUpload = e =>{
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file);
  }
  editName = e =>{
    const name = e.target.value;
    this.setState({
      name,
    });
  }
  
  editStatus = e => {
    const status = e.target.value;
    this.setState({
      status,
    });
  }
  
  handleSubmit= e =>{
    e.preventDefault();
    let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
    this.setState({
      active: activeP,
    })
  }
  
  render() {
    const {imagePreviewUrl, 
           name, 
           status, 
           active} = this.state;
    return (
      <div>
        {(active === 'edit')?(
          <Edit onSubmit={this.handleSubmit}>
            <ImgUpload onChange={this.photoUpload} src={imagePreviewUrl}/>
          
          </Edit>
        ):(
          <Profile 
            onSubmit={this.handleSubmit} 
            src={imagePreviewUrl} 
            name={name} 
            status={status}/>)}
        
      </div>
    )
  }
}

