import actions from './actions'
import { UnityContext } from "react-unity-webgl";

const initialState = {
 avatarAccessToken :null
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    // case actions.SET_DATA:
    //   console.log("inside SET DATA")
    //   console.log(action.payload)
    //   console.log(initialState.currentRealm)
    //   return {
    //     ...state,
    //     realms: {
    //       ...state.realms,
    //       [initialState.currentRealm]: {
    //         ...state.realms[initialState.currentRealm],
    //         [action.payload.currentEditorScreen]: {
    //           ...state.realms[initialState.currentRealm][action.payload.currentEditorScreen],
    //           data: action.payload.actualload,
    //         },
    //       }
    //     }
    //   }
    default:
      return state
  }
}